<template>
  <sub-nav :pages="pages" />
</template>

<script>
export default {
  name: 'SubNavEstimating',
  data() {
    return {}
  },
  computed: {
    pages() {
      return [
        {
          title: 'Item & assembly catalog',
          icon: ['fas', 'cube'],
          to: 'items'
        },
        {
          title: 'Import items',
          icon: 'cloud-upload',
          to: 'import_tool'
        },
        {
          title: 'Dimensions',
          icon: 'drafting-compass',
          to: 'dimensions'
        },
        {
          title: 'Construction stage',
          icon: 'clipboard-list',
          to: 'stages'
        },
        {
          title: 'Labor rates',
          icon: 'user-helmet-safety',
          to: 'labor'
        },
        {
          title: 'Estimating & presentation settings',
          icon: 'gear',
          to: 'estimating_defaults'
        },
        {
          title: 'Taxes',
          icon: 'landmark-flag',
          to: 'taxes'
        }
      ]
    }
  },
  props: {},
  components: {}
}
</script>
